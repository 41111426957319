import { render, staticRenderFns } from "./PaymentsPanel.vue?vue&type=template&id=4cc65d22&lang=pug&"
import script from "./PaymentsPanel.vue?vue&type=script&lang=js&"
export * from "./PaymentsPanel.vue?vue&type=script&lang=js&"
import style0 from "./PaymentsPanel.vue?vue&type=style&index=0&lang=stylus&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCard,VSwitch})
